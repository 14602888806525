import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[(this.$vuetify.breakpoint.lgAndUp)?_c(VCol,{attrs:{"lg":"3"}},[_c(VCard,{staticStyle:{"overflow-y":"auto"},attrs:{"color":"grey lighten-4","flat":"","height":_vm.cardHeight,"max-height":_vm.cardHeight}},[_c(VCardTitle,[_vm._v("Product catalog")]),_c(VTreeview,{staticClass:"text-overline",attrs:{"hoverable":"","dense":"","activatable":"","open-on-click":"","return-object":"","transition":"","items":_vm.catalogTV,"item-key":_vm.catalogTV.id},on:{"update:active":_vm.onSelectCategory},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
return [(item.catalogItemCount)?_c(VSheet,{staticClass:"transparent text-right accent--text"},[_vm._v(" "+_vm._s(item.catalogItemCount)+" ")]):_vm._e()]}}],null,false,355195342)})],1)],1):_vm._e(),_c(VCol,{attrs:{"lg":"9"}},[_c(VCard,{staticStyle:{"overflow-y":"auto"},attrs:{"flat":"","outlined":"","shaped":"","loading":_vm.loading,"height":_vm.cardHeight,"max-height":_vm.cardHeight}},[_c(VCardTitle,[(this.$vuetify.breakpoint.lgAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.currentCategory))]):_c(VMenu,{attrs:{"bottom":"","offset-y":"","nudge-right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu-down")]),_vm._v(" Product catalog ")],1)]}}])},[_c(VTreeview,{staticClass:"text-overline grey lighten-4",attrs:{"hoverable":"","dense":"","activatable":"","open-on-click":"","return-object":"","transition":"","items":_vm.catalogTV,"item-key":_vm.catalogTV.id},on:{"update:active":_vm.onSelectCategory}})],1),_c(VSpacer),_c(VTextField,{staticStyle:{"max-width":"300px"},attrs:{"dense":"","flat":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},on:{"click:append":_vm.onSearch},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('CatalogItemTable',{attrs:{"tableHeight":_vm.tableHeight}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }