<template>
	<v-container fluid class="pa-0" v-resize="onResize">
		<v-row no-gutters>
			<!-- SIDEBAR -->
			<v-col v-if="this.$vuetify.breakpoint.lgAndUp" lg="3">
				<v-card
					color="grey lighten-4"
					flat
					:height="cardHeight"
					:max-height="cardHeight"
					style="overflow-y: auto"
				>
					<v-card-title>Product catalog</v-card-title>
					<v-treeview
						class="text-overline"
						hoverable
						dense
						activatable
						open-on-click
						return-object
						transition
						:items="catalogTV"
						:item-key="catalogTV.id"
						@update:active="onSelectCategory"
					>
						<template v-slot:append="{ item }">
							<v-sheet
								v-if="item.catalogItemCount"
								class="transparent text-right accent--text"
							>
								{{ item.catalogItemCount }}
							</v-sheet>
						</template>
					</v-treeview>
				</v-card>
			</v-col>
			<!-- CATALOGITEM LIST -->
			<v-col lg="9">
				<v-card
					flat
					outlined
					shaped
					:loading="loading"
					:height="cardHeight"
					:max-height="cardHeight"
					style="overflow-y: auto"
				>
					<v-card-title>
						<!-- CURRENT CATEGORY -->
						<span v-if="this.$vuetify.breakpoint.lgAndUp">
							{{ currentCategory }}</span
						>
						<!-- MOBILE CATALOGTV -->
						<v-menu v-else bottom offset-y nudge-right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn plain v-bind="attrs" v-on="on">
									<v-icon>mdi-menu-down</v-icon> Product
									catalog
								</v-btn>
							</template>
							<v-treeview
								class="text-overline grey lighten-4"
								hoverable
								dense
								activatable
								open-on-click
								return-object
								transition
								:items="catalogTV"
								:item-key="catalogTV.id"
								@update:active="onSelectCategory"
							/>
						</v-menu>
						<v-spacer />
						<!-- SEARCH -->
						<v-text-field
							style="max-width: 300px"
							dense
							flat
							v-model="searchValue"
							append-icon="mdi-magnify"
							label="Search"
							single-line
							hide-details
							clearable
							v-on:click:append="onSearch"
							@keyup.native.enter="onSearch"
						></v-text-field>
					</v-card-title>

					<CatalogItemTable :tableHeight="tableHeight" />
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	import { mapGetters } from "vuex";
	import CatalogItemTable from "@/components/CatalogItemTable";

	export default {
		name: "ProductCatalog",
		components: { CatalogItemTable },
		data() {
			return {
				drawer: null,
				searchValue: "",
				loading: false,
				tableHeight: null,
				cardHeight: null,
			};
		},
		created() {
			this.onResize;
			this.$store.dispatch("products/fetchCatalog");
			if (!this.readOnly) {
				this.$store.dispatch("shoppingCart/fetchShoppingCart");
			}
		},
		computed: {
			...mapGetters({
				catalogTV: "products/catalogTV",
				currentCategory: "products/currentCategoryName",
				webUserInfo: "session/webUserInfo",
				readOnly: "session/readOnly",
			}),
			searchText: {
				get() {
					return this.$store.state.products.ui.searchText;
				},
				set(text) {
					this.$store.dispatch("products/setSearchText", text);
				},
			},
		},
		methods: {
			onResize() {
				this.cardHeight =
					window.innerHeight -
					this.$vuetify.application.top -
					this.$vuetify.application.footer -
					2;
				this.tableHeight = this.cardHeight;
				this.tableHeight -= 72; // card-title
				this.tableHeight -= 56; // pagination
			},
			onSearch() {
				this.loading = true;
				this.searchText = this.searchValue;
				this.$store.dispatch("products/setCurrentPage", 1).then(() => {
					this.$store
						.dispatch("products/setCurrentCategory", {})
						.then(() => {
							this.$store
								.dispatch("products/fetchCatalogItemList")
								.then(() => {
									this.loading = false;
								});
						});
				});
			},
			onSelectCategory(pCatalogTV) {
				if (!pCatalogTV[0]) return;
				this.loading = true;
				this.searchValue = "";
				this.$store.dispatch("products/setSearchText", "").then(() => {
					this.$store
						.dispatch("products/setCurrentCategory", pCatalogTV[0])
						.then(() => {
							this.$store
								.dispatch("products/setCurrentPage", 1)
								.then(() => {
									this.$store
										.dispatch("products/fetchCatalogItemList")
										.then(() => (this.loading = false));
								});
						});
				});
			},
		},
	};
</script>
